<template>
    <div v-if="me" class="container">
        <QnaHeader
            :show-left-btn="true"
            :white-mode="true"
            :left-btn-icon="'close'"
            title="인증센터"
            :right-btn-handler="onClickCertMethod"
            right-btn-cta="발급방법"
        />
        <main class="wrapper">
            <div class="read-me">
                <div class="readme-item" v-for="i in [1, 2]" :key="i">
                    <div class="star">*</div>
                    <div class="text" v-html="$translate(`CERT_CENTER_${i}`)" />
                </div>
            </div>
            <div v-if="declined.length" class="check-declined" @click="onClickDeclineReason">
                <div class="left">
                    <img :src="require(`@/assets/images/certi-center/reject.png`)" width="24px" height="24px" />
                    <span class="m-l-8">반려사유 확인하기</span>
                </div>
                <div class="right">
                    <img :src="require(`@/assets/images/icons/arrow_right.svg`)" width="16px" height="16px" />
                </div>
            </div>
            <div class="title m-b-8">필수 제출 서류</div>
            <div class="required m-b-20">
                <div class="item" @click="onSelectItem(item)" v-for="item in verifyStatus.required" :key="item.id">
                    <span v-html="item.name" />
                    <div class="right">
                        <div
                            v-if="item.status !== 'NOT_YET'"
                            class="item-badge"
                            :class="{ confirmed: item.status === 'CONFIRMED', declined: item.status === 'DECLINED' }"
                            v-html="$translate(item.status)"
                        />
                        <img :src="require(`@/assets/images/icons/arrow_right.svg`)" width="16px" height="16px" />
                    </div>
                </div>
            </div>
            <div class="sub-title m-b-4">
                <span class="m-r-4">대리로 발급되는 서류</span>
                <img
                    @click="extend = !extend"
                    :src="require(`@/assets/images/icons/info.png`)"
                    width="16px"
                    height="16px"
                />
            </div>
            <div class="mandate" v-if="extend">
                <div class="header">
                    <span class="f-16">대리로 발급되는 서류</span>
                    <img
                        @click="extend = false"
                        :src="require(`@/assets/images/icons/close.png`)"
                        width="16px"
                        height="16px"
                    />
                </div>
                <div class="items">
                    <div class="mandate-item" v-for="item in verifyStatus.mandated" :key="item.id">
                        <span v-html="item.name" />
                        <div
                            v-if="item.status !== 'NOT_YET'"
                            class="item-badge"
                            :class="{ confirmed: item.status === 'CONFIRMED' }"
                            v-html="$translate(item.status)"
                        />
                    </div>
                </div>
                <div class="notice-wrapper">
                    <div class="notice">* 직접 발급을 원하신다면 매니저에게 문의해주세요.</div>
                    <div class="notice">** 대리 발급은 무료로 진행됩니다</div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: 'CertificatePage',
    data: () => ({
        extend: false,
    }),
    props: {
        onCertiAllSubmittedMove: {
            type: Function,
        },
    },
    mounted() {
        this.init()
    },
    watch: {
        certiSubmitted(newValue) {
            if (newValue) {
                this.onCertiAllSubmitted()
            }
        },
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        verifyStatus() {
            const badges = this.$store.getters.verificationBadges
            if (!badges || !badges.length) return false

            return this.$store.getters.verifyStatus
        },
        myBadges() {
            return this.$store.getters.myVerificationBadges || []
        },
        declined() {
            return this.$store.getters.declined
        },
        certiSubmitted() {
            const requiredVeri = [13, 14, 15]
            const hasAllCerties = requiredVeri.every(r => {
                return (this.$store.getters.myVerificationBadges || []).some(b => b.verification_badge_id === r)
            })
            return hasAllCerties
        },
    },
    methods: {
        async init() {
            if (!this.verifyStatus) {
                await this.$store.dispatch('loadVerificationBadges')
            }
            await this.$store.dispatch('loadMyVerificationBadges')
        },
        onClickCertMethod() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: `https://vbproduct.notion.site/ac8a5e85d1e04f0e92a45f879fd289a1`,
            })
        },
        onClickDeclineReason() {
            this.$modal.custom({
                component: 'ModalBadgeDeclined',
                options: { reasons: this.declined, hideBottomBtn: true },
            })
        },
        async onSelectItem(item) {
            if (['PENDING', 'CONFIRMED'].includes(item.status)) return

            await this.$modal.custom({
                component: 'ModalCertificate',
                options: {
                    verificationBadge: item,
                },
            })
        },
        async onCertiAllSubmitted() {
            await this.$store.dispatch('loadMyVerificationBadges')
            if (this.onCertiAllSubmittedMove) {
                this.onCertiAllSubmittedMove()
            } else {
            }
        },
        back() {
            this.$stackRouter.pop()
        },
    },
    created() {
        this.$registerBackHandler(this.back)
    },
    beforeDestroy() {
        this.$unregisterBackHandler()
    },
}
</script>

<style lang="scss" scoped>
.bottom-border {
    border-bottom: 1px solid $grey-02;
}
.item-badge {
    padding: 4px;
    color: $blue-primary;
    background: #f4fcff;
    border-radius: 100px;
    font-size: 12px;

    &.confirmed {
        color: $grey-08;
        background: $grey-01;
    }
    &.declined {
        color: $pink-deep;
        background: $bg-danger;
    }
}
.container {
    color: #111111;

    .wrapper {
        padding: 24px 16px;

        .read-me {
            padding: 16px 12px;
            background: $grey-01;
            border-radius: 4px;
            font-size: 14px;
            margin-bottom: 24px;

            .readme-item {
                display: flex;
                line-height: 20px;
            }
        }
        .check-declined {
            @include between;
            padding: 8px;
            background: $bg-danger;
            border-radius: 8px;
            margin-bottom: 24px;

            .left {
                @include center;
            }
        }

        .title {
            @include spoqa-f-bold;
            font-size: 16px;
            line-height: 26px;
        }
        .sub-title {
            font-size: 14px;
            color: $grey-08;
            display: flex;
            align-items: center;
        }
        .item {
            border: 1px solid $grey-04;
            padding: 16px;
            margin-bottom: 8px;
            border-radius: 8px;
            font-size: 16px;
            color: black;
            @include between;

            .right {
                font-size: 12px;
                @include center;
            }
        }
        .mandate {
            width: calc(100vw - 36px);
            margin: 0 auto;
            border: 1px solid $grey-04;
            border-radius: 8px;
            padding: 18px 12px;
            font-size: 14px;

            .header {
                @include between;
                font-size: 14px;
                color: black;
                margin-bottom: 10px;
            }
            .items {
                .mandate-item {
                    @include between;
                    margin-bottom: 8px;
                    color: black;
                }
            }
            .notice-wrapper {
                color: $grey-08;
                line-height: 20px;
            }
        }
    }
}
</style>
